import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root'})
export class FranquiaService {

    private URL = `${environment.api}`;
    userToken: any = localStorage.getItem('pipoohUser');

  constructor(private http: HttpClient, private toastr: ToastrService) { }
  authFormData(formData)
  {
    this.userToken = localStorage.getItem('pipoohUser');
    formData.token = this.userToken;
    return formData;
  }
  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });
  }

  getFranquia(formData) {
    return this.postData(`${this.URL}franquia`, this.authFormData(formData));
  }
  getCEP(formData) {
    return this.postData(`${this.URL}franquia/admin/select/cep`, this.authFormData(formData));
  }
}
