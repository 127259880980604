import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';

declare let mLayout: any;
@Component({
    selector: "app-menu-mobile",
    templateUrl: "./menu-mobile.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class MenuMobileComponent implements OnInit {


    constructor() {

    }
    ngOnInit() {

    }
    // ngAfterViewInit() {

    //     mLayout.initAside();

    // }

}