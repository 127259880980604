import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { environment } from '../../../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

    public URL = `${environment.domain}`;
    public URLNova = `https://img.pipooh.com.br/`;
    infoUser: any = [];
    imagem: any;
    constructor(private _authService: AuthService, 
        private _router: Router) {

    }
    ngOnInit() {
        this.getInfoUser();
    }
    getInfoUser(){
        let data = localStorage.getItem('pipoohUser'); 
        this._authService.getToken({"token":data}).toPromise().then(data =>
            {
                this.infoUser = data;
                this.infoUser = this.infoUser.payload[0];
                if(this.infoUser == false || this.infoUser == null) { this._authService.logout();  this._router.navigate([`/logout`]); }
                this.imagem = this.URLNova + this.infoUser.UserFoto;
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initHeader();

    }

}