import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HrefPreventDefaultDirective } from './../../_directives/href-prevent-default.directive';
import { UnwrapTagDirective } from './../../_directives/unwrap-tag.directive';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { LayoutComponent } from './layout/layout.component';
import { QuickSidebarComponent } from './quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { TemplateComponent } from './template/template.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';
import {MatIconModule} from '@angular/material/icon'



@NgModule({
    declarations: [
        LayoutComponent,
        MenuMobileComponent,
        TemplateComponent,
        HeaderNavComponent,      
        AsideNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
    ],
    exports: [
        LayoutComponent,
        MenuMobileComponent,
        TemplateComponent,
        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule
    ]
})
export class LayoutModule {
}