export const environment = {
  production: true,
  domain: 'https://pipooh.com.br/',
  api: 'https://apiv2.pipooh.com.br/'
};

export const environment_v3 = {
  production: true,
  domain: 'https://pipooh.com.br/',
  api: 'https://apiv3.pipooh.com.br/'
};


