import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
    {
        'path': '',
        'component': AdminComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'admin',
                'loadChildren': '.\/pages\/dashboard\/dashboard.module#DashboardModule',
            },
            {
                'path': 'afiliado',
                'loadChildren': '.\/pages\/afiliado\/afiliado.module#AfiliadoModule',
            },
            {
                'path': 'assinatura',
                'loadChildren': '.\/pages\/assinatura\/assinatura.module#AssinaturaModule',
            },
            {
                'path': 'banners',
                'loadChildren': '.\/pages\/banners\/banners.module#BannersModule',
            },
            {
                'path': 'buffetFluxo',
                'loadChildren': '.\/pages\/buffetFluxo\/buffetFluxo.module#BuffetFluxoModule',
            },
            {
                'path': 'cupom',
                'loadChildren': '.\/pages\/cupom\/cupom.module#CupomModule',
            },
            {
                'path': 'baseConvites',
                'loadChildren': '.\/pages\/baseConvite\/baseConvite.module#BaseConviteModule',
            },
            {
                'path': 'convites',
                'loadChildren': '.\/pages\/convites\/convites.module#ConvitesModule',
            },
            {
                'path': 'contatoEstabelecimento',
                'loadChildren': '.\/pages\/contatoEstabelecimento\/contatoEstabelecimento.module#ContatoEstabelecimentoModule',
            },
            {
                'path': 'mkt',
                'loadChildren': '.\/pages\/mkt\/mkt.module#MktModule',
            },
            {
                'path': 'pedidoAfiliado',
                'loadChildren': '.\/pages\/pedidoAfiliado\/pedidoAfiliado.module#PedidoAfiliadoModule',
            },
            {
                'path': 'premiosAfiliado',
                'loadChildren': '.\/pages\/premiosAfiliado\/premiosAfiliado.module#PremiosAfiliadoModule',
            },
            {
                'path': 'bonus',
                'loadChildren': '.\/pages\/bonus\/bonus.module#BonusModule',
            },
            {
                'path': 'resgate',
                'loadChildren': '.\/pages\/resgate\/resgate.module#ResgateModule',
            },
            {
                'path': 'relatorios',
                'loadChildren': '.\/pages\/relatorios\/relatorios.module#RelatoriosModule',
            },
            {
                'path': 'pedido',
                'loadChildren': '.\/pages\/pedido\/pedido.module#PedidoModule',
            },
            {
                'path': 'troca',
                'loadChildren': '.\/pages\/troca\/troca.module#TrocaModule',
            },
            {
                'path': 'produto',
                'loadChildren': '.\/pages\/produto\/produto.module#ProdutoModule',
            },
            {
                'path': 'quizz-podmae',
                'loadChildren': '.\/pages\/quizPodmae\/quiz_podmae.module#QuizPodmaeModule',
            },
            {
                'path': 'quizz-podmae-edit',
                'loadChildren': '.\/pages\/quizzPodmaeEdit\/quizz_podmae_edit.module#QuizzPodmaeEditModule',
            },
            {
                'path': 'estabelecimento',
                'loadChildren': '.\/pages\/estabelecimento\/estabelecimento.module#EstabelecimentoModule',
            },
            {
                'path': 'empresa',
                'loadChildren': '.\/pages\/empresas\/empresa.module#EmpresaModule',
            },
            {
                'path': 'pedidoEstabelecimento',
                'loadChildren': '.\/pages\/pedidoEstabelecimento\/pedidoEstabelecimento.module#PedidoEstabelecimentoModule',
            },
            {
                'path': 'ranking',
                'loadChildren': '.\/pages\/ranking\/ranking.module#RankingModule',
            },
            {
                'path': 'rankingPromocao',
                'loadChildren': '.\/pages\/rankingPromocao\/rankingPromocao.module#RankingPromocaoModule',
            },
            {
                'path': 'usuario',
                'loadChildren': '.\/pages\/usuario\/usuario.module#UsuarioModule',
            },
            {
                'path': 'organizador',
                'loadChildren': '.\/pages\/organizador\/organizador.module#UsuarioModule',
            },
            {
                'path': 'giftEstabelecimento',
                'loadChildren': '.\/pages\/servicoGift\/servicoGift.module#ServicoGiftModule',
            },
            {
                'path': '',
                'redirectTo': 'admin',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'admin',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }