import { Injectable } from "@angular/core";
import { HttpClient   } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AuthService } from '../../admin/_services/auth.service';
import { Router } from "@angular/router";
import { AlertService } from ".";

@Injectable()
export class AuthenticationService {

    user: any = [];
    constructor(private _router: Router,private authService: AuthService, private _alertService: AlertService,private http: HttpClient  ) {
    }

    async login(email: string, password: string) {
        var obj = { "email": email, "pass": password };
        await this.authService.login(obj).then(data => {
            console.log("data: ", data);
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.token);
        }
        else{
            return false;
        }           
             
    }

    async forgotPassword(email: string) {
        var obj = { "email": email };
        await this.authService.forgotPassword(obj).toPromise().then(data => {
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.token);
        }
        else{
            return false;
        }           
             
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('pipoohUser');
    }
}