import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import * as moment from 'moment';
import { HelperService } from '../admin/_services/helper.service';
import { FranquiaService } from '../admin/_services/franquia.service';
import { AuthService } from '../admin/_services/auth.service';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    regioes: any = [];
    lat: string;
    long: string;
    coords: any;
    user: any;

    @ViewChild('alertSignin', { read: ViewContainerRef, static: true }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef, static: true }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef, static: true }) alertForgotPass: ViewContainerRef;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private toastr: ToastrService,
        private _franquiaService: FranquiaService,
        private _helperService: HelperService,
        private cfr: ComponentFactoryResolver,
        private authService: AuthService) {
    }

    ngOnInit() {
        this.model.remember = true;
        this.model.apresentacao = "mamãe";
        this.model.checkCodigo = false;
        this.model.codigo = "";
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });

        this._franquiaService.getFranquia({}).then((data: any) => {
            this.regioes = data.payload;
        })
        // var auxCoords;
        // if (navigator.geolocation) {
        //     var infoCoords = navigator.geolocation.getCurrentPosition(
        //         function(position) {
        //             return position;
        //         },
        //         function errorCallback(error) {
        //             //do error handling
        //         }
        //     );  
        //     console.log("CCC", infoCoords);
        // } 
        // console.log("BBB",auxCoords);
    }

    signin() {
        this.loading = true;
        var obj = { "email": this.model.email, "pass": this.model.password };
        this.authService.login(obj).then(data => {
            console.log("data: ", data);
            this.user = data;
            if (this.user.payload) {
            } if (this.user.payload.length > 0) {
                //Funcionou
                localStorage.setItem('pipoohUser', this.user.payload[0].token);
                if( this.user.payload[0].fkPerfil_Adm == 1){
                    this._router.navigate(["convites"]);
                }else{
                    this._router.navigate([this.returnUrl]);

                }
            } else {
                this._alertService.error(
                    'E-mail ou senha inválida.',
                    true);
            }

            this.showAlert('alertSignin');
            this.loading = false;
        })
    }
    forgotPass() {
        this.loading = true;
        var aux: any = [];
        var obj = { "email": this.model.email };
        this.authService.forgotPassword(obj).toPromise().then(data => {
            this.user = data;
            this.showAlert('alertSignin');
            if (this.user.payload) {
                if (this.user.payload.length > 1) {
                    localStorage.setItem('pipoohUser', this.user.payload[0].token);
                } else {
                    this.toastr.error('E-mail informado não está cadastrado.', 'Falha na recuperação');
                }

            }
            this.loading = false;
            this.model = {};
        })


        // this._authService.forgotPassword(this.model.email).then(data => {
        //     console.log(data);
        //     aux = data;
        //     this.showAlert('alertSignin');
        //     if (data == false) {
        //         this.toastr.error('E-mail informado não está cadastrado.', 'Falha na recuperação');
        //     }
        //     else {
        //         this._alertService.success(
        //             'Legal! A instrução de recuperação de senha foi enviada para seu e-mail.',
        //             true);
        //         //this._router.navigate([this.returnUrl]);
        //         LoginCustom.displaySignInForm();
        //     }
        //     this.loading = false;
        //     this.model = {};
        // })
    }
    logout() {
        localStorage.removeItem('pipoohUser');
    }
    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}