import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    infoUser: any = [];
    constructor(
        private _authService: AuthService,
    ) {

    }
    ngOnInit() {
        this.getInfoUser();
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }

    getInfoUser() {
        let data = localStorage.getItem('pipoohUser');
        this._authService.getToken({ "token": data }).toPromise().then(data => {
            this.infoUser = data;
            this.infoUser = this.infoUser.payload[0];
            console.log("infoUser", this.infoUser )
            //if(this.infoUser.fkFranquia == null){ this._router.navigate([`/endereco`]); }
        }).catch(e => { console.log(e); });
    }

}